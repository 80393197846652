import React from 'react';
import AuthContainer from './AuthContainer';
import Logo from './Logo';
import LoginForm from './LoginForm';
import Banner from './Banner';

interface AuthProps {
  onLogin: (email: string, password: string) => void;
  success: boolean;
  loading: boolean;
}

const Auth: React.FC<AuthProps> = ({ onLogin, success, loading }) => {
  return (
    <div className="min-h-screen bg-gray-100 relative">
      <Banner />
      {loading && (
        <div className="flex justify-center mt-20 absolute inset-0 z-50">
          <div className="loader border-t-4 border-blue-500 rounded-full w-16 h-16 animate-spin bg-white border"></div>
        </div>
      )}
      <div className="flex justify-center items-center h-full">
        <AuthContainer>
          <Logo />
          <h2 className="text-white text-center text-lg mb-6">LOG IN</h2>
          {!success && (
            <div className="text-red-500 text-center mb-4">
              Username / Password incorrect
            </div>
          )}
          <LoginForm onLogin={onLogin} />
        </AuthContainer>
      </div>
    </div>
  );
};

export default Auth;