import React, { useState } from 'react';

interface LoginFormProps {
  onLogin: (email: string, password: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(email, password);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-3 rounded-lg bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4" />
      <input type="password" placeholder="Password"  value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-3 rounded-lg bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4" />
      <div className="text-right text-sm text-gray-400 mb-6">
        <a href="/" className="hover:underline">Forgot password? Click <span className="text-blue-400">here</span></a>
      </div>
      <button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition">LOG IN</button>
    </form>
  );
};

export default LoginForm;
