import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import { login, checkAuth } from './components/utils/auth'
import Cookies from 'js-cookie'
import { isTokenExpired } from './components/utils/auth';
import { UserDetailsProvider } from './components/UserProfile/MyDetails/UserDetailsContext';

// Lazy load the MainApp component
const MainApp = lazy(() => import('./components/MainApp/MainApp'));

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // Start with loading set to true
  const [success, setSuccess] = useState<boolean>(true);
  const navigate = useNavigate();

  const onLogin = async (email: string, password: string) => {
    setLoading(true);
    try {
      const temp = await login(email, password);
      setSuccess(temp);
      if (temp) {
        setIsAuthenticated(true);
        navigate('/app');
      } else {
        setSuccess(false);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const verifyAuth = async () => {
      const token = Cookies.get('token');
      if (token && !isTokenExpired(token)) {
        const { isAuthenticated } = await checkAuth();
        setIsAuthenticated(isAuthenticated);
        if (!isAuthenticated) {
          navigate('/login');
        } else {
          // if on login page and authenticated, redirect to user profile
          if (window.location.pathname === '/login') {
            navigate('/app/userprofile/details');
          }
        }
      } else {
        navigate('/login');
      }
      setLoading(false); // Set loading to false once the check is complete
    };

    verifyAuth();
  }, [navigate]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center mt-20 absolute inset-0 z-50">
          <div className="loader border-t-4 border-blue-500 rounded-full w-16 h-16 animate-spin bg-white border"></div>
        </div> // Display a loading indicator while verifying authentication
      ) : (
        <Routes>
          <Route path="/login" element={<Auth onLogin={onLogin} success={success} loading={loading} />} />
          <Route
            path="/app/*"
            element={
              isAuthenticated ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <UserDetailsProvider>
                    <MainApp />
                  </UserDetailsProvider>
                </Suspense>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/" element={<Navigate to={isAuthenticated ? "/app" : "/login"} />} />
        </Routes>
      )}
    </>
  );
};

export default App;