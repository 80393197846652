import React from 'react';

const AuthContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-[#0a2540] p-10 rounded-lg shadow-lg max-w-sm w-full">
        {children}
      </div>
    </div>
  );
};

export default AuthContainer;