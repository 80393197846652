import axios from 'axios';
import Cookies from 'js-cookie';
import { CategoryResponse } from './get_categories';
import { apiUrl } from './set_api';

export const getUserCategories = async (): Promise<CategoryResponse[]> => {
    try {
        const token = Cookies.get('token');

        if (!token) {
            throw new Error("User is not authenticated. Token is missing.");
        }

        const response = await axios.get<CategoryResponse[]>(`${apiUrl}/users/user_categories/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });

        return response.data;  // This will be the list of user files
    } catch (error) {
        console.error('Failed to fetch user files:', error);
        throw error;  // Re-throw the error so it can be handled by the calling function
    }
};


export const postUserCategory = async (category_id: number): Promise<string> => {
    try {
        const token = Cookies.get('token');

        if (!token) {
            throw new Error("User is not authenticated. Token is missing.");
        }

        const response = await axios.post<string>(`${apiUrl}/users/user_categories/`, { category: category_id}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });

        return response.data;  // This will be the list of user suppliers
    } catch (error) {
        console.error('Failed to post new user category:', error);
        throw error;  // Re-throw the error so it can be handled by the calling function
    }
};

export const deleteUserCategory = async (category_id: number): Promise<void> => {
    try {
        const token = Cookies.get('token');

        if (!token) {
            throw new Error("User is not authenticated. Token is missing.");
        }

        await axios.delete(`${apiUrl}/users/user_categories/${category_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
        
        return  // Should be deleted

    } catch (error) {
        console.error('Failed to delete user supplier:', error);
        throw error;  // Re-throw the error so it can be handled by the calling function
    }
};