import React from 'react';

const Logo: React.FC = () => {
  return (
    <div className="flex flex-col items-center mb-6">
      <img src={"/Handshake.png"} alt="Handshake" className="h-12 mb-2" />
      <h1 className="text-white text-2xl font-semibold">handshake</h1>
    </div>
  );
};

export default Logo;
