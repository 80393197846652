import React from 'react';

const Banner: React.FC = () => {
  return (
    <div className="bg-[#0a2540] py-4 px-6">
      <div className="flex items-center">
        <img src="/Handshake.png" alt="Handshake Logo" className="h-8 mr-3" />
        <span className="text-white text-xl font-semibold">handshake</span>
      </div>
    </div>
  );
};

export default Banner;
